
	import {mapGetters} from 'vuex';
	import methodsDescriptions from "@/helpers/methodsDescriptions";

	import Dropdown from '@/components/ui-kit/Dropdown.vue'
	import UiButton from '@/components/ui-kit/buttons/Button'
	import Checkbox from "@/components/ui-kit/form/Checkbox.vue";
	import UiRadioButton from "@/components/UI/Radio/UiRadioButton.vue";
	import Tooltip from "@/components/ui-kit/Tooltip.vue";

	import gtmButtonClick from '@/mixins/gtm-button-click';

	export default {
		name: "FilterPsychologists",
		mixins: [gtmButtonClick],
		components: {Tooltip, UiRadioButton, Checkbox, Dropdown, UiButton},
		props: {
			value: {type: Object},
			isNotPsychologist: Boolean
		},
		data() {
			return {
				tempFilter: JSON.parse(JSON.stringify(this.value)),
				period: 'any',
				chosenPeriod: 'any',

				genderOptions: [
					{label: 'Женщина', value: 'female'},
					{label: 'Мужчина', value: 'male'},
					{label: 'Не важно', value: 'any'}
				],
				periodOptions: [
					{label: 'Любое', value: 'any'},
					{label: 'В ближайщее время', value: 'today'},
					{label: 'Определенное', value: 'accurate'}
				],
				ageOptions: [
					{label: 'Любой', ge: 18, le: 100},
					{label: 'от 20 до 30', ge: 20, le: 30},
					{label: 'от 30 до 40', ge: 30, le: 40},
					{label: 'от 40 до 50', ge: 40, le: 50},
					{label: '50+', ge: 50, le: 100},
				]
			}
		},
		computed: {
			...mapGetters({
				getTherapyMethods: 'dictionary/getTherapyMethods',
			}),
			methodOptions() {
				const getMethodId = (key) => {
					let method = this.getTherapyMethods.items.find(m => m.name === key);
					return method?.id;
				}

				let methods = [];

				for (const key in methodsDescriptions) {
					methods.push({
						id: getMethodId(key),
						...methodsDescriptions[key]
					})
				}
				return methods;
			},
			weekdays() {
				if (this.period === 'accurate') {
					return this.tempFilter.time.weekdays || []
				}
				return []
			},
			weekend() {
				if (this.period === 'accurate') {
					return this.tempFilter.time.weekend || []
				}
				return []
			},
			timeDisplayName() {
				let displayName = '';

				if (!this.value?.time || this.value?.time === 'any') {
					displayName = 'Любое';
				} else if (this.value?.time === 'today') {
					displayName = 'В ближайщее время';
				} else {
					if (this.value?.time?.weekdays) {
						let items = [];
						this.value?.time?.weekdays.forEach(day => {
							switch (day) {
								case 'morning': {
									items.push('утро')
									break;
								}
								case 'afternoon': {
									items.push('день')
									break;
								}
								case 'evening': {
									items.push('вечер')
									break;
								}
							}
						});

						displayName = 'Будни: ' + items.join(', ');
					}

					if (this.value?.time?.weekend) {
						let items = [];
						this.value?.time?.weekend.forEach(day => {
							switch (day) {
								case 'morning': {
									items.push('утро')
									break;
								}
								case 'afternoon': {
									items.push('день')
									break;
								}
								case 'evening': {
									items.push('вечер')
									break;
								}
							}
						});

						displayName += (this.value?.time?.weekdays ? ', ': '') + 'Выходные: ' + items.join(', ');
					}
				}

				return displayName;
			}
		},
		methods: {
			apply(close, filterType) {
				close()

				if (this.period === 'accurate' && !this.tempFilter.time.weekdays && !this.tempFilter.time.weekend) {
					this.choosePeriod(this.chosenPeriod === 'accurate' ? 'any' : this.chosenPeriod)
				}

				this.$emit('input', JSON.parse(JSON.stringify(this.tempFilter)));
				this.chosenPeriod = this.period;

				this.$emit('apply');

				try {
					this.gtmButtonClick(
						filterType,
						'apply',
						'form',
						'sendform',
						'clientPage'
					);
				} catch (e) {
					console.log(e);
				}
			},
			cancel() {
				this.tempFilter = JSON.parse(JSON.stringify(this.value))
				this.period = this.chosenPeriod
			},

			addRemoveMethod(id) {
				if (!this.tempFilter.therapy_method_id__in.includes(id)) {
					this.tempFilter.therapy_method_id__in.push(id)
				} else {
					this.tempFilter.therapy_method_id__in = this.tempFilter.therapy_method_id__in.filter(el => el !== id)
				}
			},
			chooseGender(value) {
				this.tempFilter.gender = value
			},
			changeAge(start, end) {
				this.tempFilter.age.ge = start
				this.tempFilter.age.le = end
			},
			choosePeriod(period) {
				if (period === 'any') {
					delete this.tempFilter.time
				} else if (period === 'accurate') {
					this.tempFilter.time = {}
				} else if (period === 'today') {
					this.tempFilter.time = 'today'
				}

				this.period = period
			},
			chooseTime(days, time) {
				if (this.period !== 'accurate') {
					this.choosePeriod('accurate')
				}

				let newTimes = [...(this.tempFilter.time[days] || [])]

				if (newTimes.includes(time)) {
					newTimes = newTimes.filter(el => el !== time)
					if (newTimes.length) {
						this.tempFilter.time[days] = newTimes
					} else {
						delete this.tempFilter.time[days]
					}
				} else {
					newTimes.push(time)
					this.tempFilter.time[days] = newTimes
				}
			}
		},

		mounted() {
			if (!this.value?.time) {
				this.period = 'any';
				this.chosenPeriod = 'any';
			} else if (this.value.time.weekdays || this.value.time.weekend) {
				this.period = 'accurate';
				this.chosenPeriod = 'accurate';
			} else {
				this.period = this.value?.time;
				this.chosenPeriod = this.value?.time;
			}
		}
	}
