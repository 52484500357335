
	export default {
		props: {
			state: Boolean,
			name: {type: String, default: ''},
			value: {type: String, default: ''},
		},
		computed: {
			check() {
				return !!this.state
			},
		},
		methods: {
			updateState() {
				this.$emit('updateState', this.value)
			},
		},
	}
