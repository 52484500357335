
import UiRadioItem from './UiRadioItem.vue'

export default {
  components: {
    UiRadioItem,
  },
  props: {
    state: Boolean,
    name: { type: String, default: '' },
    text: { type: String, default: '' },
    value: { type: String, default: '' },
  },
}
