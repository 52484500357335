
	import {ValidationProvider} from 'vee-validate';
	import UiIcon from "@/components/UI/Icon/UiIcon";
	import Tooltip from "@/components/ui-kit/Tooltip";

	export default {
		name: "Checkbox",
		components: {Tooltip, ValidationProvider, UiIcon},
		props: {
			id: {type: String, required: true},
			name: {type: String, required: true},
			value: {type: Boolean},
			label: {type: String, default: ''},
			required: {type: Boolean},
			mode: {
				validator(value) {
					// https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html#interaction-modes
					return ['aggressive', 'passive', 'eager', 'lazy'].includes(value)
				},
				default: 'passive',
			},
			rules: {type: Array, default: () => []}, // ['required']
		},
		emits: ['update:modelValue'],
		computed: {
			classes() {
				const classes = [];
				if (!this.label) {
					classes.push('checkbox--without-label')
				}
				if (this.error) {
					classes.push('checkbox--error')
				}
				return classes;
			},
			validateRules() {
				const rules = [...this.rules];

				if (this.required && rules.indexOf('required') === -1) {
					rules.push('required');
				}

				return rules.join("|");
			}
		},
		methods: {
			changeValue(event) {
				this.$emit('input', event.target.checked);

				if (event.target.checked) {
					this.$emit('checked');
				}

				if (this.mode === 'passive') {
					this.$refs.provider.reset();
				}
			},
		},
	}
