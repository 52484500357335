
	import UiIcon from "@/components/UI/Icon/UiIcon.vue";
	import ClickOutside from 'vue-click-outside'

	export default {
		name: "Dropdown",
		components: {UiIcon},
		directives: {
			ClickOutside,
		},
		props: {
			position: {
				type: String,
				default: ''
			},
			flatToggle: Boolean,
			hideCaret: Boolean,
			menuClass: {type: String, default: ''}
		},
		data() {
			return {
				isOpened: false,
			}
		},
		watch: {
			isOpened(value) {
				if (!value) {
					this.$emit('onClose')
				}
			}
		},
		methods: {
			toggle() {
				this.isOpened = !this.isOpened
			},
			close() {
				if (this.isOpened) {
					this.isOpened = false
				}
			}
		}
	}
